<template>
  <div>
    <AlterFormLayout
      modalId="benefactor-add-modal"
      formTitle="Solicitar benefício de benemérito"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de benefício de benemérito"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <BenefactorForm :benefactorTypeOptions="$props.benefactorTypeOptions" />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Olá Cooperado(a), acusamos o recebimento de sua solicitação e as tratativas estão em andamento."
      subdescription="Em breve retornaremos."
      buttonText="Ok, entendi!"
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import BenefactorForm from "./BenefactorForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { requestBenefactor } from "../../../../services/benefactor/benefactor-service";

export default {
  props: ["benefactorTypeOptions"],
  components: {
    AlterFormLayout,
    BenefactorForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    async onSubmit(formData) {
      this.protocol = await requestBenefactor(formData);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
  },
};
</script>
