import FormRequestAction from "../../static-data/FormRequestAction";
import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { METALICA_URL } from "../urls/base-urls";
import mapperToBenefactorRequest from "./benefactor-mapper";

export async function requestBenefactor(formData) {
  var originalData = mapperToBenefactorRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToBenefactorRequest(
    formData,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      [],
      "DOCTOR_BENEFACTOR_SEND",
      null,
      changedData
    )
  );
  return resp.data;
}
