<template>
  <b-container fluid>
    <b-row class="justify-content-center  my-2">
      <b-card class="custom-card flex-grow-1 my-4 p-5">
        <h5>
          O benefício de Cooperado Benemérito permite que o (a) médico (a) opte
          por parar de produzir ou seguir com os atendimentos médicos sem
          necessidade de cumprir a produção mínima.
        </h5>
        <h5>
          O cooperado benemérito seguirá usufruindo do Plano Médico Cooperado,
          através do pagamento de mensalidade e também dos demais benefícios
          disponibilizados pela Cooperativa.
        </h5>
        <h5 class="mt-5">
          Para ter direito ao benefício o (a) cooperado (a) deverá atender aos
          seguintes critérios:
        </h5>
        <h5>
          Ter desempenhado regularmente suas atividades com a Cooperativa por
          pelo menos 15 (quinze) anos ininterruptos, sem a existência de
          condenação em processo ético-administrativo.
        </h5>
        <h5>
          No caso da existência de processo em andamento, terá que aguardar o
          transitado/julgado.
        </h5>
        <h5>
          <span class="font-weight-bold">Para homens:</span> Idade igual ou
          acima de 65 (sessenta e cinco) anos;
        </h5>
        <h5>
          <span class="font-weight-bold">Para mulheres:</span> Idade igual ou
          acima de 60 (sessenta) anos;
        </h5>
        <h5 class="mt-5">
          Através da benemerência, o(a) Dr.(a) poderá optar por continuar
          atuando ou não, conforme segue abaixo :
        </h5>
        <h5>
          |)
          <span class="font-weight-bold">Com produção (Benemérito ativo)</span>
          - O Cooperado continuará exercendo suas atividades médicas pela Unimed
          e não será mais cobrado pela produção mínima ( atualmente com o valor
          equivalente a 30 consultas/mês); ou
        </h5>
        <h5 class="mt-5">
          ||)
          <span class="font-weight-bold"
            >Sem produção (Benemérito afastado)</span
          >
          - O Cooperado deixará de atuar como médico pela Unimed, mas continuará
          como Cooperado na Unimed Grande Florianópolis.
        </h5>
        <h5 class="mt-5">
          ⇨Continuarão usufruindo do Plano Médico Cooperado, através do
          pagamento de mensalidade e demais benefícios disponibilizados pela
          Cooperativa, e poderão, mediante livre opção, continuar a realizar
          atendimentos médicos no âmbito da Cooperativa (se optarem por ser
          beneméritos ativos).
        </h5>
        <h5 class="mt-5">
          As diferenças entre ser um benemérito ativo ou afastado são que
          <span class="font-weight-bold">
            o afastado não mais poderá candidatar-se a cargos eletivos da
            Cooperativa, votar ou ser votado.</span
          >
          O benemérito ativo mantém os direitos de antes e apenas ganha a
          premissa de não ter mais que cumprir a produção mínima. Tanto o
          benemérito ativo quanto afastado ainda estarão sujeitos às demais
          normas da Cooperativa e suas penalidades. Mesmo sendo benemérito, o
          Cooperado não poderá cobrar consultas de pacientes que sejam da
          Unimed.
        </h5>
        <h5 class="mt-5">
          Não poderão obter a condição de Cooperado Benemérito, aqueles
          cooperados que estiverem afastados de suas atividades laborais médicas
          por invalidez permanente, independentemente do tempo de cooperação.
        </h5>
        <h5>
          Para obter a condição de Cooperado Benemérito, você deverá solicitar
          clicando no botão “Solicitar Benemérito”.
        </h5>
        <h5>
          Veja as orientações completas na Seção de Direitos dos Cooperados do
          Regimento Interno disponível no Portal do Cooperado.
        </h5>
      </b-card>
    </b-row>
    <b-row class="my-auto">
      <b-button
        class="my-3"
        variant="primary"
        v-b-modal:benefactor-add-modal
        v-show="!this.loading"
      >
        Solicitar benemérito
      </b-button>
    </b-row>

    <BenefactorAddModal :benefactorTypeOptions="benefactorTypeOptions" />

    <SuccessModal
      description="Solicitação de alteração enviada!"
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import BenefactorAddModal from "../forms/BenecfactorAddModal.vue";

export default {
  name: "benefactor-tab",
  components: {
    SuccessModal,
    BenefactorAddModal,
  },
  data() {
    return {
      benefactorTypeOptions: [
        { value: null, text: "Selecione um tipo", disabled: true },
        {
          value: "Ativo",
          text: "Ativo",
        },
        {
          value: "Afastado",
          text: "Afastado",
        },
      ],
      loading: false,
    };
  },
};
</script>

<style lang="scss">
p.center {
  padding: 0rem 4rem;
}
</style>
