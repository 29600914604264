<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: 1 - Ao escolher o tipo de benemérito "Ativo", o cooperado
      poderá continuar realizando atendimentos. 2 - Ao escolher o tipo de
      benemérito “Afastado", o cooperado não poderá mais realizar atendimentos.
    </p>

    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          TIPO DE BENEMÉRITO
        </b-col>
        <b-col>
          <b-form-select
            v-model.lazy.trim="form.benefactorType"
            :state="validateField('benefactorType')"
            :options="$props.benefactorTypeOptions"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-form-checkbox
          id="checkbox-aceite"
          v-model.lazy.trim="form.accepted"
          name="checkbox-aceite"
          value="Sim"
          unchecked-value="Não"
          :state="validatedFieldAccepted('accepted')"
        >
          Eu declaro que estou ciente das regras e orientações referente a
          solicitação de Benemérito
        </b-form-checkbox>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import {
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
} from "../../../../utils/validate/validate.js";

const DefaultForm = {
  benefactorType: null,
  accepted: "Não",
  files: null,
  originalData: null,
};

export default {
  props: ["benefactorTypeOptions"],
  components: {},
  setup() {
    const setSubmitHandler = inject("setSubmitHandler");
    const setForm = inject("setForm");

    return {
      setSubmitHandler,
      setForm,
    };
  },
  mounted() {
    this.setSubmitHandler(this.onSubmit);
  },
  data() {
    return {
      localBenefactorTypeOptions: [],
      formSubmited: false,
      accepted: "Não",
      form: {
        ...DefaultForm,
      },
      validations: {},
      validateField,
      requiredFieldsFilled,
      validateFieldFile,
    };
  },
  methods: {
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.form.originalData = { ...this.form };
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    validatedFieldAccepted(name) {
      let value = this.form[name];
      let validation = (value == "Não") ? false : true;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
